/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */

 @import '~normalize.css/normalize.css';

 @font-face {
   font-family: 'PSTimes';
   src: url('/public/PSTimes-Regular.woff2') format('woff2');
   font-weight: 400;
   font-display: normal;
 }

 @font-face {
   font-family: 'Favorit';
   src: url('/public/ABCFavorit-Bold.woff2') format('woff2');
   font-weight: 700;
   font-display: normal;
 }


 @font-face {
  font-family: 'Favorit';
  src: url('/public/ABCFavorit-Regular.woff2') format('woff2');
  font-weight: 400;
  font-display: normal;
}

 * {
   font-family: Favorit, Arial, sans-serif;
 }

 h1, h2, h3, h4, h5, h6 {
   font-family: PSTimes, "Times New Roman", serif;
 }
